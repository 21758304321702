<template>
  <div class="app-container">

    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入商品订单后4位/用户手机号" style="width: 280px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.distributeman_id" placeholder="分餐员" style="width: 140px" clearable filterable>
        <el-option v-for="item in distributemans" :key="item.id" :label="item.user.name" :value="item.id"></el-option>
      </el-select>
      <el-input v-model="listQuery.batch_no" placeholder="请输入批次号" style="width: 200px;" class="filter-item" clearable/>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="expand" min-width="40">
        <template slot-scope="props">
          <div style="margin-left: 10%">
            <el-form v-if="props.row.order.send_type == 1" label-position="left" inline class="custom-inline-form">
              <el-row>收货人信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.order.receiver.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.order.receiver.phone }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>{{ props.row.order.receiver.address }}</span>
              </el-form-item>
            </el-form>
            <el-form v-else label-position="left" inline class="custom-inline-form">
              <el-row>收货人信息</el-row>
              <el-form-item label="姓名:">
                <span>{{ props.row.order.user.name }}</span>
              </el-form-item>
              <el-form-item label="手机号码:">
                <span>{{ props.row.order.user.phone }}</span>
              </el-form-item>
              <el-form-item label="地址:">
                <span>{{ props.row.order.device_order.device_name }}-{{ props.row.order.device_order.box_name }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>配送信息</el-row>
              <el-form-item label="配送方式:">
                <span>{{ props.row.order.send_type == 1 ? '配送服务' : '智慧餐柜' }}</span>
              </el-form-item>
              <el-form-item :label="props.row.order.send_type == 3 ? '预计自取时间:' : '预计送达时间:'">
                <span>{{ props.row.order.send_time ? props.row.order.send_time : '尽快送出' }}</span>
              </el-form-item>
              <el-form-item label="送餐方式:" v-if="props.row.order.send_type == 1">
                <span v-if="props.row.order.is_sideboard == 1">投入餐柜</span>
                <span v-else>上楼配送</span>
              </el-form-item>
              <br/>
              <el-form-item label="骑手:">
                <span>{{ props.row.order.sendman ? `${props.row.order.sendman.name}-${props.row.order.sendman.phone}` : '无' }}</span>
              </el-form-item>
              <el-form-item label="骑手取餐时间:">
                <span>{{ props.row.order.sendman_take_time ? `${props.row.order.sendman_take_time}` : '无' }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="分拣员:">
                <span>{{ props.row.distributeman ? `${props.row.distributeman.user.name}-${props.row.distributeman.user.phone}` : '无' }}</span>
              </el-form-item>
              <el-form-item label="分拣时间:">
                <span>{{ props.row.distributeman ? `${props.row.created_at}` : '无' }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="配餐状态:" v-if="props.row.order.send_type == 1">
                <span v-if="props.row.order.is_meal_timeout == 1">已超时</span>
                <span v-else>未超时</span>
              </el-form-item>
              <el-form-item label="配送状态:" v-if="props.row.order.send_type == 1">
                <span v-if="props.row.order.is_shipping_timeout == 1">已超时</span>
                <span v-else>未超时</span>
              </el-form-item>
              <el-form-item :label="props.row.order.send_type == 3 ? '外卖自取时间:' : '外卖送达时间:'">
                <span>{{ props.row.order.shipping_time ? props.row.order.shipping_time : '无' }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>订单信息</el-row>
              <el-form-item label="学校:">
                <span>{{ props.row.order.school.school_name }}</span>
              </el-form-item>
              <el-form-item label="店铺:">
                <span>{{ props.row.order.shop.shop_name }}</span>
              </el-form-item>
              <el-form-item label="订单编号:">
                <span>{{ props.row.order.order_no }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="商品总价:">
                <span>{{ props.row.order.original_price }}元</span>
              </el-form-item>
              <el-form-item label="包装费:">
                <span>{{ props.row.order.pack_price }}元</span>
              </el-form-item>
              <el-form-item label="配送费:">
                <span>{{ props.row.order.send_price }}元</span>
              </el-form-item>
              <el-form-item label="订单总价:">
                <span>{{ props.row.order.total_price }}元</span>
              </el-form-item>
              <el-form-item label="优惠金额:">
                <span>{{ props.row.order.discount_money }}元</span>
                <span v-if="props.row.order.discount_type == 1">(优惠券)</span>
                <span v-else-if="props.row.order.discount_type == 2">(抵扣{{props.row.order.discount_integral}}积分)</span>
                <span v-else-if="props.row.order.discount_type == 3">(会员{{props.row.order.discount_percent}}折)</span>
              </el-form-item>
              <el-form-item label="实付金额:">
                <span>{{ props.row.order.pay_money }}元</span>
              </el-form-item>
              <br/>
              <el-form-item label="下单时间:">
                <span>{{ props.row.order.created_at }}</span>
              </el-form-item>
              <el-form-item label="支付时间:">
                <span>{{ props.row.order.pay_time }}</span>
              </el-form-item>
              <el-form-item label="退款时间:">
                <span>{{ props.row.order.refund_time }}</span>
              </el-form-item>
              <br/>
              <el-form-item label="备注:">
                <span>{{ props.row.order.remark }}</span>
              </el-form-item>
            </el-form>
            <el-form label-position="left" inline class="custom-inline-form">
              <el-row>商品信息</el-row>
              <div v-for="(item, index) in props.row.order.details" :key="index">
                <el-form-item label="ID:">
                  <span>{{ item.id }}</span>
                </el-form-item>
                <el-form-item label="名称:">
                  <span>{{ item.goods_name }}</span>
                </el-form-item>
                <el-form-item label="规格属性:">
                  <span>{{ item.goods_spec_attr_info }}</span>
                </el-form-item>
                <el-form-item label="价格:">
                  <span>{{ item.total_price }}元</span>
                </el-form-item>
                <el-form-item label="数量:">
                  <span>{{ item.goods_num }}</span>
                </el-form-item>
                <el-form-item label="合计:">
                  <span>{{ item.total_price }}元</span>
                </el-form-item>
                <br/>
              </div>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="打印号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order.shop_count }}
        </template>
      </el-table-column>
      <el-table-column label="下单时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="分餐员" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.distributeman.user.name }}</div>
          <div>{{ scope.row.distributeman.user.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="分餐时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="批次号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.batch_no }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";

export default {
  data() {
    return {
      distributemans: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        start_time: "",
        end_time: "",
        distributeman_id: '',
        batch_no: "",
      },
      dialogVisible: false,
      btnLoading: false
    };
  },
  created() {
    this.getList();
    this.getDistributemanList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getDistributemanList();
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/distributeman/order",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getDistributemanList() {
      request({
        url: "/api/backend/distributeman/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.distributemans = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.custom-inline-form {
  margin-bottom: 10px;
  .el-row {
    padding: 4px 0;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 4px !important;
  }
  .el-form-item__label {
    line-height: 20px !important;
  }
  .el-form-item__content {
    line-height: 20px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
